<template>
  <div data-app>
    <ParameterList
      :parameters="parameters"
      v-bind:getParametros="getParametros"
    ></ParameterList>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import { GET_PARAMETROS } from "@/core/services/store/setting.module";
import ParameterList from "../components/parameter/ParameterList.vue";
export default {
  name: "parameters",
  components: {
    ParameterList,
  },
  data() {
    return {
      parameter: null,
      parameters: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Parametros", route: "parameters" },
    ]);
  },
  created() {
    this.getParametros();
  },
  methods: {
    getParametros() {
      KTUtil.setLoading();
      const oVue = this;
      oVue.parameters = [];
      oVue.$store.dispatch(GET_PARAMETROS).then((parameters) => {
        oVue.parameters = parameters;
        KTUtil.removeLoading();
      });
    },
  },
};
</script>
