<template>
  <div>
    <v-data-table
      :headers="headersParameters"
      :items="parameters"
      :sort-by="['id']"
      :sort-desc="true"
      :search="search"
      class="table-striped elevation-1 table-fixed-last"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Listado de Parametros</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div style="display: inline-flex">
          <ParameterEdit
            :parameter="item"
            v-bind:getParametros="getParametros"
          ></ParameterEdit>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import ParameterEdit from "./ParameterEdit.vue";
export default {
  name: "ParameterList",
  components: {
    ParameterEdit,
  },
  props: {
    parameters: Array,
    getParametros: Function,
  },
  data() {
    return {
      headersParameters: [
        {
          text: "ID",
          align: "center",
          value: "id",
        },
        {
          text: "Descripción",
          value: "descripcion",
        },
        {
          text: "Valor",
          value: "valor",
          align: "center",
        },
        { text: "", value: "actions", sortable: false },
      ],
      search: "",
    };
  },
};
</script>
